import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import './api/request.js'
// import animated from 'animate.css'
import 'element-ui/lib/theme-chalk/index.css'
import VideoPlayer from 'vue-video-player/src'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'
import { Button, Carousel, CarouselItem, Table, TableColumn, Drawer,Dropdown,DropdownItem,DropdownMenu } from 'element-ui'

// axios的配置
axios.defaults.baseURL = 'https://gw.sczyzx.cn/'
// axios.defaults.baseURL = 'http://1.14.14.218:9091/'
Vue.prototype.$http = axios

Vue.config.productionTip = false

Vue.use(Button)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Drawer)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
// Vue.use(animated)
Vue.use(VideoPlayer)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

import Vue from 'vue'
import VueRouter from 'vue-router'
import getPageTitle from '@/utils/title'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/Home.vue'),
    meta: {
      title: "四川卓远企业征信咨询有限公司"
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/About.vue'),
    meta: {
      title: "关于我们"
    }
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('../views/News.vue'),
    meta: {
      title: "新闻动态"
    }
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import('../views/Detail.vue'),
    meta: {
      title: "新闻动态-详情"
    }
  },
  {
    path: '/product',
    name: 'product',
    component: () => import('../views/Product.vue'),
    meta: {
      title: "产品与服务"
    }
  },
  {
    path: '/solutions',
    name: 'solutions',
    component: () => import('../views/Solutions.vue'),
      meta: {
        title: "解决方案"
      }
  },
  {
    path: '/construction',
    name: 'construction',
    component: () => import('../views/Construction.vue'),
    meta: {
      title: "党的建设"
    }
  },
  {
    path: '/join',
    name: 'join',
    component: () => import('../views/Join.vue'),
    meta: {
      title: "招贤纳士"
    }
  },
  {
    path: '/chat',
    name: 'chat',
    component: () => import('../views/Chat.vue'),
    meta: {
      title: "AI客服"
    }
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  scrollBehavior: () => ({ y: 0 }),
  routes
})

router.beforeEach(async (to, from, next) => {
  document.title = getPageTitle(to.meta.title)
  next()
})

export default router
